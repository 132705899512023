import {Component, OnInit} from '@angular/core';
import {environment} from '@env';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss']
})
export class ContentLayoutComponent implements OnInit {

  isEnvLocal = false;

  constructor() {
  }

  ngOnInit() {
    this.isEnvLocal = (environment.envName === 'LOCAL');
    // throw new Error(`This will be a very long error message that we will see how long it extends over and what the close looks like!`);
  }
}
