import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
// Services
import {NotificationService} from '../service/notification.service';

@Injectable()
export class ErrorsHandler implements ErrorHandler {


  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  constructor(private injector: Injector) {
  }

  handleError(error: Error | HttpErrorResponse) {

    const notifier = this.injector.get(NotificationService);

    var status: number = 0;
    if (!notifier) {
      console.debug("notifier is undefined");
      if (error) console.log(error.message);
    } else if (!navigator.onLine) {
      // Handle offline error
      notifier.showError('Browser Offline!');
    } else {
      if (error instanceof HttpErrorResponse) {
        let errorHttp = error as HttpErrorResponse;
        status = errorHttp.status;
        // Handle Http Error (4xx, 5xx, ect.)
        notifier.showError('Http Error: ' + status + '::' + error.message);
        throw error;
      } else {
        // Handle Client Error (Angular Error, ReferenceError...)
        notifier.showError(error.message);
      }
      // Always log the error
      console.error('ERROR: '+status+'::'+error.message);
    }
  }
}
