import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ContentLayoutComponent} from '@layout/content-layout/content-layout.component';

const routes: Routes = [
    {path: '', redirectTo: '/home', pathMatch: 'full'},
    {
        path: '',
        component: ContentLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('@modules/pkgtool/pkgtool.module').then(m => m.PackageToolModule)
            }
        ]
    },
    {path: '**', redirectTo: '/home', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {
}
