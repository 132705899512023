import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';

import {CoreModule} from '@core/core.module';
import {SharedModule} from '@shared/shared.module';

import {ContentLayoutComponent} from '@layout/content-layout/content-layout.component';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

@NgModule({
  declarations: [
    AppComponent
    , ContentLayoutComponent
  ],
  imports: [
    // angular
    BrowserModule
    , BrowserAnimationsModule
    , HttpClientModule

    // core & shared
    , CoreModule
    , SharedModule

    // app
    , AppRoutingModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
