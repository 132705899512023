// `.env.ts` is generated by the `npm run env` command
import env from './.env';

export const environment = {
  production: false,
  version: env.npm_package_version,
  protoServices: null,
  hostPortServices: 'researchdata.acs.org',
  baseUrlServices: '/pkgtoolsvc/service',
  urlPrefixUploads: '/uploads',
  fileUploadMaxSixedBytes: 31457280, /* 30Mb */
  envName: 'PROD2',
};
