import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';

@Injectable()
export class HttpsInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //console.log('request: '+request.headers.get('x-auth-token'));
    //const headerName = 'XSRF-TOKEN';
    //let xsrf = request.headers.get(headerName);
    //console.log('xsrf: ' + xsrf)

    let token = window.localStorage.getItem('auth-token');
    // console.log('adding token to request: '+token);
    const req = request.clone({
      headers: request.headers.set('x-auth-token', token)
    });
    // console.log('url: '+req.url);
    return next.handle(req).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        console.debug('error.status: ' + error.status);
        if (error.status === 0) {
          console.debug('HttpErrorResponse unknown error');
        } else if (error.status === 401) {
          // Attempt to refresh the current token, however if that fails redirect to login
          console.debug('HttpErrorResponse 401 error');
        } else {
          console.debug('HttpErrorResponse '+error.status)
        }
        return throwError(error);
      })
    );
  }
}

@Injectable()
export class HttpXSRFInterceptor implements HttpInterceptor {

  //
  // constructor(private tokenExtractor: HttpXsrfTokenExtractor) {
  // }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerName = 'XSRF-TOKEN';
    let xsrf = req.headers.get(headerName);
    console.log('xsrf: ' + xsrf);
    // let token = this.tokenExtractor.getToken() as string;
    // if (token !== null && !req.headers.has(headerName)) {
    //     req = req.clone({ headers: req.headers.set(respHeaderName, token) });
    // }
    return next.handle(req);
  }
}
