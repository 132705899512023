import {Injectable} from '@angular/core';
//import {MatSnackBar} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  //constructor(public snackBar: MatSnackBar) {
  constructor() {
  }

  showError(message: string) {
    console.log(message);
    // let sb = this.snackBar.open(message, undefined, {duration: 3000});
    // sb.onAction().subscribe(() => {
    //   //console.log("clicked");
    //   //sb.dismiss();
    // });
    // sb.afterDismissed().subscribe(() => {
    //   //console.log("dismissed");
    // });
  }
}
