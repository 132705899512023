import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
//import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';

//import {SharedMaterialModule} from './material.module';

@NgModule({
  imports: [
    CommonModule
    //, FormsModule
    , FlexLayoutModule
    , RouterModule
    //, SharedMaterialModule
  ],
  declarations: [
  ],
  exports: [
    CommonModule
    //, FormsModule
    , FlexLayoutModule
    , RouterModule
    //, SharedMaterialModule
  ]
})
export class SharedModule {
}
