import {ErrorHandler, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
//import {Injector} from '@angular/core';
//import {AppInjectorService} from './service/app-injector.service';

// Providers
import {ErrorsHandler} from './provider/error-handler';
import {HttpsInterceptor} from './provider/http-interceptor';

@NgModule({
    declarations: [],
    imports: [],
    providers: [
        {provide: ErrorHandler, useClass: ErrorsHandler},
        {provide: HTTP_INTERCEPTORS, useClass: HttpsInterceptor, multi: true}
        //{ provide: HTTP_INTERCEPTORS, useClass: HttpXSRFInterceptor, multi: true }
    ]
})
export class CoreModule {
}
